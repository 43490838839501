import React from 'react';
import { makeStyles } from '@mui/styles';
import { deepOrange } from '@material-ui/core/colors';
import { Avatar } from '@material-ui/core';
import coverImage from '@assets/images/customer/cover.png';
import avatarImage from '@assets/images/customer/avatar.png';
import { Divider, Grid } from '@mui/material';
import moment from 'moment';
import { formatDateToString } from '@components/helper';

export const Personal = ({ customer }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <div className={classes.cover}>
            <img src={coverImage} alt="Cover" className={classes.coverImage} />
          </div>
        </Grid>
        <Grid item md={2} xs={4} marginLeft={{ xs: 2 }}>
          <Avatar
            alt="User Avatar"
            src={avatarImage}
            className={`${classes.avatar} ${classes.orange}`}></Avatar>
        </Grid>
      </Grid>
      <Grid container marginLeft={{ md: 4, xs: 2 }}>
        <Grid item md={12} xs={12}>
          <span style={{ fontSize: '18px', fontWeight: 700 }}>
            {customer?.full_name ?? 'Không xác định'}
          </span>
          <br></br>
          <span style={{ fontSize: '14px', fontWeight: 400 }}>
            {customer?.email ?? 'Không xác định'}
          </span>
        </Grid>
      </Grid>
      <Grid container marginLeft={{ md: 4, xs: 2 }} marginTop={4}>
        <Grid item md={12} xs={12}>
          <Divider />
          <h2 style={{ fontSize: '16px', fontWeight: 500 }}>Thông tin cá nhân</h2>
        </Grid>
        <Grid item md={6} xs={6}>
          <TextView label={'Tên đăng nhập'} value={customer?.username ?? '-'} />
          <TextView
            label={'Giới tính'}
            value={customer?.gender ? (customer?.gender == 'MALE' ? 'Nam' : 'Nữ') : '-'}
          />
          <TextView
            label={'Thời gian tạo'}
            value={customer?.created_date ? formatDateToString(customer?.created_date) : '-'}
          />
        </Grid>
        <Grid item md={6} xs={6}>
          <TextView
            label={'Ngày sinh'}
            value={
              customer?.date_of_birth ? moment(customer?.date_of_birth).format('DD-MM-YYYY') : '-'
            }
          />
          <TextView label={'Địa chỉ'} value={customer?.address ?? '-'} />
          <TextView
            label={'Trạng thái'}
            value={customer?.status === true ? 'Đang hoạt động' : 'Không hoạt động'}
          />
        </Grid>
      </Grid>
    </div>
  );
};

const TextView = ({ label, value }) => {
  return (
    <div style={{ marginBottom: 8 }}>
      <span style={{ fontSize: '12px', fontWeight: 400, marginBottom: '10px' }}>{label}</span>
      <br></br>
      <span style={{ fontSize: '14px', fontWeight: 500 }}>{value}</span>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  card: {
    width: '100%',
    height: '128px',
    backgroundColor: '#ccc', // You can change this to an image URL,
    borderRadius: '8px'
  },
  coverImage: {
    width: '100%',
    height: '128px',
    objectFit: 'cover',
    borderRadius: '8px'
  },
  avatar: {
    width: theme.spacing(15),
    height: theme.spacing(15),
    margin: '-85px auto 0', // Adjust position as needed
    border: `5px solid ${theme.palette.background.paper}`
  },
  orange: {
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500]
  }
}));
